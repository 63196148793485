<template>
    <div class="products-list" ref="productList">
        <vs-card type="3" style="width: 100%;" v-for="p in products" :key="'product-'+p.id">
            <template #title>
                <h3>{{ p.title }}</h3>
            </template>
            <template #img>
                <img class="product-image" :src="p.previewFileUrl || placeholder" :alt="p.title">
            </template>
            <template #text>
                <div class="card-content">
                    <p class="product-description">
                        {{ p.description }}
                    </p>
                    <vs-button block color="primary" class="animate" v-if="drafts[p.id]" @click="goToDrafts(p.id)">
                        <i class='bx bx-shopping-bag bx-sm'></i> Continua il tuo progetto
                    </vs-button>
                    <vs-button animation-type="scale" block @click="$router.push({name: 'store', params: {productId: p.id}})" v-else>
                        <i class='bx bx-right-arrow-alt bx-sm'></i>
                        <template #animate>
                            Configura ora
                        </template>
                    </vs-button>
                </div>
            </template>
        </vs-card>
    </div>
</template>
<script>
import {
    apiCall
} from '@/client.js';
import placeholder from '@/assets/placeholder.png';
export default {
    name: 'ProductList',
    props: {
        products: {
            type: Array,
            required: true
        },

        noDrafts: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            placeholder: placeholder,
            drafts: {}
        }
    },

    methods: {

        // go to drafts with query param productId
        goToDrafts(productId){
            this.$router.push({
                name: 'drafts',
                query: {
                    productId
                }
            });
        },

        // get last order draft
        async getLastDraft(productId){
            try {
                var result = await apiCall('GET', '/Order', {
                    page: 0,
                    productId,
                    status: 'Draft'
                });

                if(result.status == 200){
                    this.drafts[productId] = result.data[0];
                }
            } catch (error) {
                console.error(error);
            }

        },

        async loadDrafts(){
            if(this.noDrafts){
                return;
            }

            const loading = this.$vs.loading({
                target: this.$refs.productList,
            });

            await Promise.all(this.products.map(async p => {
                await this.getLastDraft(p.id);
            }));

            this.drafts.__ob__.dep.notify();


            loading.close();
        }
    },

    async mounted(){
        this.loadDrafts();

    },

    watch: {
        products(n){
            this.loadDrafts();
        }
    }
}
</script>
<style scoped>
.card-content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 35px);
}

.product-description {
    flex-grow: 1;
    margin: 0 0 15px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    line-height: 1.5em;
}
</style>

<style>
.products-list .vs-card__img {
    max-width: 40%!important;
    min-width: 40%!important;
}
.products-list .vs-card-content.type-3 {
    margin-bottom: 20px;
}

.products-list .vs-card__img {
    min-height: 230px;
}

.products-list .vs-card {
    max-width: unset!important;
}

.products-list p {
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    line-height: 1.5em;
}

.product-image {
    max-height: 200px;
    max-width: 200px;
}
</style>